import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from 'docComponents';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="August 2019"
      subnav="release-notes">
      <div id="August2019" className={styles.release}>
        <Paragraph className="uni-margin--one--vert">
          While dainty in nature, this release is anything but. The alert has
          come to React Native with its own set of rules, and we dipped our toes
          into the pond—we mean ocean—that is data visualization thanks to the
          help of a buddy.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />

            <div className={styles.changesList}>
              <Added>
                Mobile usage guidelines when using the{' '}
                <Link href="/components/alert/design">alert</Link>.
              </Added>
              <Added>
                Guidelines for using the{' '}
                <Link href="/components/legend/design">legend</Link> for shot
                charts and other groups of data.
              </Added>
              <Fixed>
                An issue when typing a keyboard shortcut into the code snippet
                would switch the page you were viewing—woops.
              </Fixed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="7.4.1"
              versionUrl="https://github.com/hudl/rn-uniform-ui/releases/tag/v7.4.1"
            />
            <div className={styles.changesList}>
              <Added>
                {' '}
                The <Link href="/components/alert/design">alert</Link> to use
                for anything account related in the app.
              </Added>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="3.32.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases/tag/v3.32.1"
            />
            <Added>
              The <Link href="/components/legend/design">legend</Link>{' '}
              component. Thanks for contributing,{' '}
              <Link href="https://github.com/mybuddymichael">Michael</Link>!
            </Added>
            <Fixed>
              Hover state of <Link href="/components/avatars/design">avatars</Link> when onClick is provided.
            </Fixed>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
